<template>
  <b10-base>
    <b10-toolbar
      :title="title"
    >
      <v-btn
        slot="right"
        icon
        @click.stop="clickSubmit($refs.form)"
      >
        <v-icon>{{ $vuetify.icons.values.check }}</v-icon>
      </v-btn>
    </b10-toolbar>
    <b10-page-content
      :padding="3"
    >
      <ValidationObserver
        ref="form"
      >
        <lsolicitud-material-form
          v-if="dbReady"
          :idarticulo="routeParams.idarticulo"
          :idsolicitud-material="routeParams.idsolicitud_material"
          @change-values="changeFormData"
        />
      </ValidationObserver>
    </b10-page-content>
  </b10-base>
</template>

<script>
import { formPageMixin } from '@/mixins/formPageMixin'
import LsolicitudMaterialForm from '../components/LsolicitudMaterialForm'
import Data from './LsolicitudMaterialAddData'

export default {
  components: {
    LsolicitudMaterialForm
  },
  mixins: [formPageMixin],
  props: {
    viewRoute: {
      type: String,
      default: '',
    },
  },
  async created () {
    await this.initStore()
    await this.initDB()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      this.title = 'Añadir material a solicitud'
    },
    changeFormData (formData) {
      this.formData = formData
    },
    async submitForm () {
      await Data.insert(
        this,
        this.formData,
        this.routeParams.idsolicitud_material,
      )
      this.$appRouter.replace({
        name: this.viewRoute,
        params: {
          idsolicitud_material: this.routeParams.idsolicitud_material
        },
      })
    },
  },
}
</script>
