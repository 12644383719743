import { LORDEN_TRABAJO, SOLICITUD_MATERIAL } from '@/utils/consts'
import { v4 as uuidv4 } from 'uuid'

export default {
  async insert (Vue, formData, idsolicitudMaterial) {
    const parteTrabajo = await Vue.$offline.solicitudMaterial.selectParteDeSolicitud(idsolicitudMaterial)
    // no existe el subsistema
    const idsubsis = await Vue.$offline.subsis.creaSubsisDeForm(formData, parteTrabajo.orden_trabajo.idsistema)
    // Inserto la linea de OT y de detalle
    const idlordenTrabajo = await Vue.$offline.lordenTrabajo.insertLordenYDetalleSync(
      parteTrabajo.parte_trabajo.idorden_trabajo, //idordenTrabajo
      formData.codigo, //codigoArticulo
      formData.unidades,
      idsubsis, //idsubsis
      formData.tinclusion, //tinclusion
      true, //facturar
      null, //idtmotivoNofacturable
      null, //descripcionArticulo
      0, //kilometraje
      null, //observaciones
      LORDEN_TRABAJO.estados.pendiente_gestion, //idlestOrdenTrabajo
      idsolicitudMaterial,
    )
    const lordenTrabajoDetalle = await Vue.$offline.lordenTrabajoDetalle.selectEscandalloDeIdlordenTrabajo (
      null, null, null, 0, idlordenTrabajo
    )
    for (const detalle of lordenTrabajoDetalle[0]) {
      const idlsolicitudMaterial = uuidv4()
      await Vue.$offline.lsolicitudMaterial.insertSync({
        idsolicitud_material: idsolicitudMaterial,
        idlsolicitud_material: idlsolicitudMaterial,
        estado: 1,
        idest_lsolicitud_material: SOLICITUD_MATERIAL.estados.pendienteValidacion, // TODO: revisar, el pkg_solicitud_material.CrearSolicitudDesdeOT lo pone así, fijo
        codigo: detalle.lorden_trabajo_detalle.codigo,
        descripcion: detalle.lorden_trabajo_detalle.descripcion,
        idarticulo: detalle.lorden_trabajo_detalle.idarticulo,
        unidades_reservadas: 0,
      })
      await Vue.$offline.llsolicitudMaterial.insertSync({
        idllsolicitud_material:uuidv4(),
        idlsolicitud_material:idlsolicitudMaterial,
        estado: 1,
        idest_llsolicitud_material: SOLICITUD_MATERIAL.estados.pendienteValidacion, // TODO: revisar, el pkg_solicitud_material.CrearSolicitudDesdeOT lo pone así, fijo
        idlorden_trabajo_detalle: detalle.lorden_trabajo_detalle.idlorden_trabajo_detalle,
        codigo: detalle.lorden_trabajo_detalle.codigo,
        descripcion: detalle.lorden_trabajo_detalle.descripcion,
        idarticulo: detalle.lorden_trabajo_detalle.idarticulo,
        unidades: detalle.lorden_trabajo_detalle.unidades,
      })
    }
  },
}
